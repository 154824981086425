import { cn } from "@/lib/utils";

export default function DashBoard ({ className = '', stroke = 'white' }) {
  return (
  <svg className={cn(className)} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.375 16.875C11.9638 16.875 14.0625 14.7763 14.0625 12.1875C14.0625 9.59867 11.9638 7.5 9.375 7.5C6.78617 7.5 4.6875 9.59867 4.6875 12.1875C4.6875 14.7763 6.78617 16.875 9.375 16.875Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M17.8125 9.375H29.0625" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M17.8125 15H29.0625" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M20.625 20.625H29.0625" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2.11133 22.5C2.52746 20.89 3.46672 19.4638 4.78151 18.4457C6.0963 17.4275 7.71211 16.875 9.37504 16.875C11.038 16.875 12.6538 17.4275 13.9686 18.4457C15.2834 19.4638 16.2226 20.89 16.6388 22.5" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  );
}