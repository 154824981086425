import { cn } from "@/lib/utils";
import { FC } from 'react';

interface GridFourProps {
  className?: string;
  stroke?: string;
}

const GridFour: FC<GridFourProps> = ({ className = '', stroke = '' }) => {
  return (
    <svg 
      className={cn(className)} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.3438 3.09375H3.65625C3.34559 3.09375 3.09375 3.34559 3.09375 3.65625V14.3438C3.09375 14.6544 3.34559 14.9062 3.65625 14.9062H14.3438C14.6544 14.9062 14.9062 14.6544 14.9062 14.3438V3.65625C14.9062 3.34559 14.6544 3.09375 14.3438 3.09375Z" 
      stroke={stroke} 
      strokeWidth="1.2" 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
      <path d="M9 3.09375V14.9062" 
      stroke={stroke} 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
      <path d="M14.9062 9H3.09375" 
      stroke={stroke} 
      strokeLinecap="round" 
      strokeLinejoin="round"/>
    </svg>
  );
};

export default GridFour;