import { cn } from "@/lib/utils";
import { ImageProps } from "@/types";

interface PdfProps extends Readonly<ImageProps> {
    readonly className?: string;
}

export default function Pdf({ className = '' }: Readonly<PdfProps>) {
    return (
        <svg className={cn(className)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.50781 13.0273V4.77734C4.50781 4.57843 4.58683 4.38767 4.72748 4.24701C4.86813 4.10636 5.0589 4.02734 5.25781 4.02734H14.2585L19.5085 9.27734V13.0273" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.2583 4.02734V9.27734H19.5091" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5083 19.0273H6.0083C6.40613 19.0273 6.78766 18.8693 7.06896 18.588C7.35027 18.3067 7.5083 17.9252 7.5083 17.5273C7.5083 17.1295 7.35027 16.748 7.06896 16.4667C6.78766 16.1854 6.40613 16.0273 6.0083 16.0273H4.5083V20.5273" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6333 20.5273C12.23 20.5273 12.8023 20.2903 13.2243 19.8683C13.6462 19.4464 13.8833 18.8741 13.8833 18.2773C13.8833 17.6806 13.6462 17.1083 13.2243 16.6864C12.8023 16.2644 12.23 16.0273 11.6333 16.0273H10.5083V20.5273H11.6333Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.5083 16.0273H16.8833V20.5273" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.1333 18.6523H16.8833" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
};
