import { cn } from "@/lib/utils";
import { FC } from 'react';

interface SkipBackwordProps {
  className?: string;
  stroke?: string;
}

const SkipBackword: FC<SkipBackwordProps> = ({ className = '', stroke = '' }) => {
  return (

    <svg className={cn(className)}
      width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.72291 8.52097L13.2067 3.33644C13.292 3.28433 13.3896 3.25587 13.4895 3.254C13.5894 3.25213 13.688 3.27692 13.7752 3.3258C13.8623 3.37468 13.9349 3.44589 13.9854 3.53212C14.0359 3.61835 14.0625 3.71648 14.0625 3.81641V14.1855C14.0625 14.2854 14.0359 14.3835 13.9854 14.4698C13.9349 14.556 13.8623 14.6272 13.7752 14.6761C13.688 14.725 13.5894 14.7497 13.4895 14.7479C13.3896 14.746 13.292 14.7175 13.2067 14.6654L4.72291 9.48091C4.64068 9.43066 4.57275 9.36013 4.52561 9.27609C4.47848 9.19204 4.45372 9.0973 4.45372 9.00094C4.45372 8.90458 4.47848 8.80983 4.52561 8.72579C4.57275 8.64174 4.64068 8.57121 4.72291 8.52097V8.52097Z" stroke="#4D5059" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.9375 2.8125V15.1875" stroke="#4D5059" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default SkipBackword;