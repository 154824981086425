import { cn } from "@/lib/utils";
import { FC } from 'react';

interface SunDimProps {
  className?: string;
  stroke?: string;
}

const SunDim: FC<SunDimProps> = ({ className = '', stroke = '' }) => {
  return (
    <svg 
      className={cn(className)} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9 13.7188C11.33 13.7188 13.2188 11.83 13.2188 9.5C13.2188 7.17005 11.33 5.28125 9 5.28125C6.67005 5.28125 4.78125 7.17005 4.78125 9.5C4.78125 11.83 6.67005 13.7188 9 13.7188Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 3.03125V2.46875" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.42607 4.92607L4.02832 4.52832" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.53125 9.5H1.96875" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.42607 14.0742L4.02832 14.472" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 15.9688V16.5312" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.5742 14.0742L13.972 14.472" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.4688 9.5H16.0312" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.5742 4.92607L13.972 4.52832" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default SunDim;