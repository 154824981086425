import { cn } from "@/lib/utils";
import { FC } from 'react';

interface ArrowsOutSimpleProps {
  className?: string;
  stroke?: string;
}

const ArrowsOutSimple: FC<ArrowsOutSimpleProps> = ({ className = '', stroke = ''}) => {
  return (
    <svg 
      className={cn(
        className
      )} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M11.25 3.375H14.625V6.75" 
        stroke={stroke} 
        strokeWidth="1.2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M10.6875 7.3125L14.625 3.375" 
        stroke={stroke} 
        strokeWidth="1.2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M6.75 14.625H3.375V11.25" 
        stroke={stroke} 
        strokeWidth="1.2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />    
      <path 
        d="M7.3125 10.6875L3.375 14.625" 
        stroke={stroke} 
        strokeWidth="1.2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowsOutSimple;