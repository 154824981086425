import { cn } from "@/lib/utils";
import { FC } from 'react';

interface SkipForwardProps {
  className?: string;
  stroke?: string;
}

const SkipForward: FC<SkipForwardProps> = ({ className = '', stroke = '' }) => {
  return (
    <svg className={cn(className)}
      width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2771 8.52097L4.79332 3.33644C4.70805 3.28433 4.61044 3.25587 4.51053 3.254C4.41061 3.25213 4.31201 3.27692 4.22485 3.3258C4.13769 3.37468 4.06513 3.44589 4.01462 3.53212C3.96412 3.61835 3.9375 3.71648 3.9375 3.81641V14.1855C3.9375 14.2854 3.96412 14.3835 4.01462 14.4698C4.06513 14.556 4.13769 14.6272 4.22485 14.6761C4.31201 14.725 4.41061 14.7497 4.51053 14.7479C4.61044 14.746 4.70805 14.7175 4.79332 14.6654L13.2771 9.48091C13.3593 9.43066 13.4273 9.36013 13.4744 9.27609C13.5215 9.19204 13.5463 9.0973 13.5463 9.00094C13.5463 8.90458 13.5215 8.80983 13.4744 8.72579C13.4273 8.64174 13.3593 8.57121 13.2771 8.52097V8.52097Z" stroke="#4D5059" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.0625 2.8125V15.1875" stroke="#4D5059" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default SkipForward; 
