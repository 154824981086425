import { cn } from "@/lib/utils";
import { FC } from 'react';

interface BookmarkSimpleProps {
  className?: string;
  stroke?: string;
}

const BookmarkSimple: FC<BookmarkSimpleProps> = ({ className = '', stroke = '' }) => {
  return (
    <svg 
      className={cn(className)} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5 16.25L8.99948 13.4375L4.5 16.25V3.875C4.5 3.72582 4.55926 3.58274 4.66475 3.47725C4.77024 3.37176 4.91332 3.3125 5.0625 3.3125H12.9375C13.0867 3.3125 13.2298 3.37176 13.3352 3.47725C13.4407 3.58274 13.5 3.72582 13.5 3.875V16.25Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default BookmarkSimple;
