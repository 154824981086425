import { cn } from "@/lib/utils";
import { FC } from 'react';

interface CircleHalfProps {
  className?: string;
  stroke?: string;
}

const CircleHalf: FC<CircleHalfProps> = ({ className = '', stroke = ''}) => {
  return (
    <svg 
      className={cn(
        className
      )}
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M9 16.25C12.7279 16.25 15.75 13.2279 15.75 9.5C15.75 5.77208 12.7279 2.75 9 2.75C5.27208 2.75 2.25 5.77208 2.25 9.5C2.25 13.2279 5.27208 16.25 9 16.25Z" 
        stroke={stroke} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M9 2.75V16.25" 
        stroke={stroke} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M9 3.875L15.6695 10.5445" 
        stroke={stroke} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M9 7.8125L14.5416 13.3541" 
        stroke={stroke} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M9 11.75L12.5139 15.2639" 
        stroke={stroke} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleHalf;