import { cn } from "@/lib/utils";

interface CaretRightProps {
  readonly className?: string;
  readonly stroke?: string;
}

export default function CaretRight({ className = '', stroke = '' }: CaretRightProps) {
  return (
    <svg 
      className={cn(className)} 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M7.5 15L12.5 10L7.5 5" 
        stroke={stroke}
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
}