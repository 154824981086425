import { EFrameType } from '@/enums/base-enum';
import { useExamStore } from '@/store/use-exam-store';
import { DiagnosticView } from '@/types/exam';

interface UseCurrentImageReturn {
  currentImage: string | null;
  currentImageDetails: {
    label: string;
    diagnosticViewName: string;
    title: string;
  };
}

export const useCurrentImage = (): UseCurrentImageReturn => {
  const { examData, activeAnotomyReferences } = useExamStore();
  const { referenceIndexes, anatomyId, frameType, frameReference } = activeAnotomyReferences;
  const { diagnosticViewIndex, frameIndex } = referenceIndexes;

  const currentAnatomy = examData?.find(exam => exam?.anatomyId === anatomyId);
  const currentDiagnosticView: DiagnosticView | undefined = currentAnatomy?.diagnosticViewData[diagnosticViewIndex];

  if (frameReference?.currentFrameBase64) {
    return {
      currentImage: frameReference.currentFrameBase64,
      currentImageDetails: {
        label: currentDiagnosticView?.label ?? '',
        diagnosticViewName: currentDiagnosticView?.diagnosticViewName ?? '',
        title: currentAnatomy?.anatomyName ?? ''
      }
    };
  }

  // If no frameReference, get frame from navigation indexes
  const currentFrame = (() => {
    if (!currentDiagnosticView) return undefined;
    
    if (frameType === EFrameType.THUMBNAIL) {
      const frame = currentDiagnosticView.thumbnails[frameIndex];
      if (!frame?.frameBase64) return undefined;
      return {
        frameBase64: frame.frameBase64,
        frameType: EFrameType.THUMBNAIL
      };
    } else {
      const clipboardIndex = frameIndex - (currentDiagnosticView.thumbnails?.length || 0);
      const frame = currentDiagnosticView.clipboards[clipboardIndex];
      if (!frame?.frameBase64) return undefined;
      return {
        frameBase64: frame.frameBase64,
        frameType: EFrameType.CLIPBOARD
      };
    }
  })();

  return {
    currentImage: currentFrame?.frameBase64 ?? null,
    currentImageDetails: {
      label: currentDiagnosticView?.label ?? '',
      diagnosticViewName: currentDiagnosticView?.diagnosticViewName ?? '',
      title: currentAnatomy?.anatomyName ?? ''
    }
  };
}; 