import { IconName } from '../../types/svg';
import Bell from './bell';
import CaretDown from './caret-down';
import CaretLeft from './caret-left';
import Vector from './vector';
import DashBoard from './dashboard';
import ReportBuilder from './report-builder';
import Gallery from './gallery';
import PatientProfile from './patient-profile';
import GalleryImage from './gallery-image';
import Pdf from './pdf';
export { default as GridFour } from './grid-four';
export { default as CircleHalf } from './circle-half';
export { default as MagnifyingGlassPlus } from './magnifying-plus';
export { default as MagnifyingGlassMinus } from './magnifying-minus';
export { default as SkipBackword } from './skip-backword';
export { default as SkipForward } from './skip-forward';
export { default as SunDim } from './sun-dim';
export { default as Camera } from './camera';
export { default as BookmarkSimple } from './bookmark';
export { default as ListBullets } from './list-bullets';
export { default as FloppyDisk } from './floppy-disk';
export { default as Clipboard } from './clipboard';
export { default as ArrowsOutSimple } from './arrows-out';
export { default as MagicWand } from './magic-wand';
export { default as DoubleForward } from './double-forward';

export const Icons: Record<
  IconName,
  React.FC<{ className?: string; stroke?: string; fill?: string }>
> = {
  "Bell": Bell,
  "CaretDown": CaretDown,
  "CaretLeft": CaretLeft,
  "Vector": Vector,
  "Dashboard": DashBoard,
  "ReportBuilder": ReportBuilder,
  "Gallery": Gallery,
  "PatientProfile": PatientProfile,
  "GalleryImage": GalleryImage,
  "Pdf": Pdf,
};