import { cn } from "@/lib/utils";
import { FC } from 'react';

interface CameraProps {
  className?: string;
  stroke?: string;
}

const Camera: FC<CameraProps> = ({ className = '', stroke = '' }) => {
  return (
    <svg 
      className={cn(className)} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.625 15.125H3.375C3.07663 15.125 2.79048 15.0065 2.5795 14.7955C2.36853 14.5845 2.25 14.2984 2.25 14V6.125C2.25 5.82663 2.36853 5.54048 2.5795 5.32951C2.79048 5.11853 3.07663 5 3.375 5H5.62459L6.74959 3.3125H11.2496L12.3746 5H14.625C14.9234 5 15.2095 5.11853 15.4205 5.32951C15.6315 5.54048 15.75 5.82663 15.75 6.125V14C15.75 14.2984 15.6315 14.5845 15.4205 14.7955C15.2095 15.0065 14.9234 15.125 14.625 15.125Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 12.3125C10.398 12.3125 11.5312 11.1792 11.5312 9.78125C11.5312 8.38328 10.398 7.25 9 7.25C7.60203 7.25 6.46875 8.38328 6.46875 9.78125C6.46875 11.1792 7.60203 12.3125 9 12.3125Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Camera;