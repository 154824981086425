import { cn } from "@/lib/utils";

export default function MagnifyingGlassPlus ({ className = '', stroke = '' }) {
  return (
    <svg 
      className={cn(className)} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.90625 8.65625H10.4062" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.15625 6.40625V10.9062" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.15625 14.5625C11.4182 14.5625 14.0625 11.9182 14.0625 8.65625C14.0625 5.39432 11.4182 2.75 8.15625 2.75C4.89432 2.75 2.25 5.39432 2.25 8.65625C2.25 11.9182 4.89432 14.5625 8.15625 14.5625Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.3325 12.832L15.7497 16.2493" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
