import { cn } from "@/lib/utils";
import { ImageProps } from "@/types";

interface GalleryImageProps extends Readonly<ImageProps> {
    readonly className?: string;
}

export default function GalleryImage({ className = '' }: Readonly<GalleryImageProps>) {
    return (
        <svg className={cn(className)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5059 4.03056L4.50586 4.02006C4.09165 4.01977 3.75562 4.35532 3.75533 4.76953L3.74483 19.7695C3.74454 20.1837 4.08009 20.5198 4.49431 20.5201L19.4943 20.5306C19.9085 20.5308 20.2445 20.1953 20.2448 19.7811L20.2553 4.78108C20.2556 4.36687 19.9201 4.03085 19.5059 4.03056Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.2475 15.279L16.2807 11.3066C16.1401 11.1658 15.9494 11.0867 15.7505 11.0865C15.5516 11.0864 15.3608 11.1653 15.22 11.3058L11.0277 15.4922C10.887 15.6328 10.6962 15.7117 10.4972 15.7115C10.2983 15.7114 10.1076 15.6322 9.96707 15.4915L8.02909 13.5508C7.88853 13.41 7.69782 13.3309 7.49891 13.3307C7.3 13.3306 7.10918 13.4095 6.96843 13.55L3.74651 16.7675" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.37635 10.0203C9.99767 10.0208 10.5017 9.51743 10.5021 8.89611C10.5026 8.27479 9.99925 7.77075 9.37793 7.77032C8.75661 7.76988 8.25258 8.27321 8.25214 8.89453C8.25171 9.51585 8.75503 10.0199 9.37635 10.0203Z" fill="none" />
        </svg>
    )
};
