import { cn } from "@/lib/utils";
import { FC } from 'react';

interface FloppyDiskProps {
  className?: string;
  stroke?: string;
}

const FloppyDisk: FC<FloppyDiskProps> = ({ className = '', stroke = ''}) => {
  return (
    <svg 
      className={cn(
        className
      )} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.625 15.125H3.375C3.07663 15.125 2.79048 15.0065 2.5795 14.7955C2.36853 14.5845 2.25 14.2984 2.25 14V4C2.25 3.70163 2.36853 3.41548 2.5795 3.2045C2.79048 2.99353 3.07663 2.875 3.375 2.875H12.0938L15.75 6.53125V14C15.75 14.2984 15.6315 14.5845 15.4205 14.7955C15.2095 15.0065 14.9234 15.125 14.625 15.125Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.9375 15.125V10.3125H5.0625V15.125" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.0625 2.875V6.53125H11.8125" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default FloppyDisk;