import { cn } from "@/lib/utils";
import { FC } from 'react';

interface ClipboardProps {
  className?: string;
  stroke?: string;
}

const Clipboard: FC<ClipboardProps> = ({ className = '', stroke = ''}) => {
  return (
    <svg 
      className={cn(
        className
      )} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M11.2501 2.8125H14.0625C14.2117 2.8125 14.3548 2.87176 14.4602 2.97725C14.5657 3.08274 14.625 3.22582 14.625 3.375V15.1875C14.625 15.3367 14.5657 15.4798 14.4602 15.5852C14.3548 15.6907 14.2117 15.75 14.0625 15.75H3.9375C3.78832 15.75 3.64524 15.6907 3.53975 15.5852C3.43426 15.4798 3.375 15.3367 3.375 15.1875V3.375C3.375 3.22582 3.43426 3.08274 3.53975 2.97725C3.64524 2.87176 3.78832 2.8125 3.9375 2.8125H6.74985" 
     stroke={stroke} 
     strokeLinecap="round" 
     strokeLinejoin="round"/>
     <path d="M6.1875 5.0625V4.5C6.1875 3.75408 6.48382 3.03871 7.01126 2.51126C7.53871 1.98382 8.25408 1.6875 9 1.6875C9.74592 1.6875 10.4613 1.98382 10.9887 2.51126C11.5162 3.03871 11.8125 3.75408 11.8125 4.5V5.0625H6.1875Z" 
     stroke={stroke} 
     strokeLinecap="round" 
     strokeLinejoin="round"/>
    </svg>
  );
};

export default Clipboard;
