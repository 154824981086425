import { cn } from "@/lib/utils";
import { FC } from 'react';

interface ListBulletsProps {
  className?: string;
  stroke?: string;
}

const ListBullets: FC<ListBulletsProps> = ({ className = '', stroke = '' }) => {
  return (
    <svg 
      className={cn(className)} 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.1875 5.28125H15.75" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.1875 9.5H15.75" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.1875 13.7188H15.75" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.8125 5.28125H2.81813" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.8125 9.5H2.81813" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.8125 13.7188H2.81813" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ListBullets;