import { cn } from "@/lib/utils";
import { FC } from 'react';

interface DoubleForwardProps {
  className?: string;
  stroke?: string;
  fillColor?: string;
}

const DoubleForward: FC<DoubleForwardProps> = ({ className = '', stroke = '', fillColor = '' }) => {
  return (
    <svg 
      className={cn(className)}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M16.9829 8.5268L10.7106 4.49461C10.6256 4.43999 10.5275 4.40925 10.4266 4.40563C10.3256 4.40202 10.2255 4.42565 10.1369 4.47406C10.0482 4.52247 9.97422 4.59386 9.92267 4.68073C9.87112 4.7676 9.84392 4.86676 9.84392 4.96777V13.0321C9.84392 13.1332 9.87112 13.2323 9.92267 13.3192C9.97422 13.4061 10.0482 13.4775 10.1369 13.5259C10.2255 13.5743 10.3256 13.5979 10.4266 13.5943C10.5275 13.5907 10.6256 13.5599 10.7106 13.5053L16.9829 9.47312C17.0621 9.42219 17.1273 9.35218 17.1724 9.26951C17.2176 9.18683 17.2412 9.09415 17.2412 8.99996C17.2412 8.90577 17.2176 8.81309 17.1724 8.73042C17.1273 8.64775 17.0621 8.57773 16.9829 8.5268V8.5268Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
       />
      <path
        d="M9.10789 8.5268L2.83559 4.49461C2.75062 4.43999 2.6525 4.40925 2.55155 4.40563C2.4506 4.40202 2.35054 4.42565 2.26187 4.47406C2.17321 4.52247 2.09922 4.59386 2.04767 4.68073C1.99612 4.7676 1.96892 4.86676 1.96892 4.96777V13.0321C1.96892 13.1332 1.99612 13.2323 2.04767 13.3192C2.09922 13.4061 2.17321 13.4775 2.26187 13.5259C2.35054 13.5743 2.4506 13.5979 2.55155 13.5943C2.6525 13.5907 2.75062 13.5599 2.83559 13.5053L9.10789 9.47312C9.18712 9.42219 9.25229 9.35218 9.29742 9.26951C9.34256 9.18683 9.36621 9.09415 9.36621 8.99996C9.36621 8.90577 9.34256 8.81309 9.29742 8.73042C9.25229 8.64775 9.18712 8.57773 9.10789 8.5268Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleForward;
