import { cn } from "@/lib/utils";
export default function Vector ( { className = '', stroke = '' }) {
  return (
    <svg className={cn("stroke-header-icons",className)}  width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0168 4.28586L12.4689 16.4138C12.424 16.5334 12.3436 16.6364 12.2384 16.7089C12.1332 16.7815 12.0083 16.82 11.8805 16.8193C11.7528 16.8187 11.6283 16.7789 11.5238 16.7054C11.4194 16.6318 11.34 16.528 11.2963 16.4079L9.47432 11.3975C9.44311 11.3117 9.39347 11.2337 9.32889 11.1692C9.26431 11.1046 9.18637 11.0549 9.10054 11.0237L4.09011 9.20176C3.97004 9.1581 3.86624 9.07869 3.79268 8.97423C3.71912 8.86977 3.67934 8.74527 3.6787 8.61751C3.67807 8.48975 3.7166 8.36486 3.78911 8.25967C3.86162 8.15448 3.96462 8.07404 4.08425 8.02918L16.2122 3.4812C16.3246 3.43906 16.4467 3.43017 16.564 3.45557C16.6812 3.48098 16.7887 3.53961 16.8736 3.62446C16.9584 3.70932 17.0171 3.81681 17.0425 3.93409C17.0679 4.05138 17.059 4.1735 17.0168 4.28586Z"
        stroke={stroke}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}