import { cn } from "@/lib/utils";

export default function MagicWand({ className = '', stroke = '' }) {
    return (
        <svg
            className={cn(
                className
            )}
            width="18"
            height="19"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15.1875 9.5V12.875" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5 11.1875H16.875" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.90625 3.3125V6.125" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 4.71875H7.3125" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.8125 13.4375V15.6875" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6875 14.5625H12.9375" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.1023 3.14775L2.64775 13.6023C2.42808 13.8219 2.42808 14.1781 2.64775 14.3977L4.10225 15.8523C4.32192 16.0719 4.67808 16.0719 4.89775 15.8523L15.3523 5.39775C15.5719 5.17808 15.5719 4.82192 15.3523 4.60225L13.8977 3.14775C13.6781 2.92808 13.3219 2.92808 13.1023 3.14775Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.125 6.125L12.375 8.375" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
