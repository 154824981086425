import { cn } from "@/lib/utils";

export default function DashBoard ({ className = '', stroke = 'white' }) {
  return (
  <svg className={cn(className)} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.6875 26.25H6.5625C6.31386 26.25 6.0754 26.1512 5.89959 25.9754C5.72377 25.7996 5.625 25.5611 5.625 25.3125V8.4375C5.625 8.18886 5.72377 7.9504 5.89959 7.77459C6.0754 7.59877 6.31386 7.5 6.5625 7.5H15.9375L20.625 12.1875V25.3125C20.625 25.5611 20.5262 25.7996 20.3504 25.9754C20.1746 26.1512 19.9361 26.25 19.6875 26.25Z" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9.375 7.5V4.6875C9.375 4.43886 9.47377 4.2004 9.64959 4.02459C9.8254 3.84877 10.0639 3.75 10.3125 3.75H19.6875L24.375 8.4375V21.5625C24.375 21.8111 24.2762 22.0496 24.1004 22.2254C23.9246 22.4012 23.6861 22.5 23.4375 22.5H20.625" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.3125 17.8125H15.9375" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.3125 21.5625H15.9375" stroke={stroke} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  );
}